export const CURRENCIES_LIST = [
	'usdt',
	'btc',
	'eth',
	'xrp',
	'doge',
	'bnb',
	'trx',
	'shib',
	'ltc',
	'xlm',
	'sushi',
	'uni',
	'etc',
	'bch',
	'ada',
	'dot',
	'optim',
	'matic',
	'pepe',
	'bonk',
	'floki',
] as const;
