export enum TokenNames {
	usdt = 'Tether',
	usdc = 'USD Coin',
	busd = 'Binance USD',
	btc = 'Bitcoin',
	eth = 'Ethereum',
	doge = 'DogeCoin',
	trx = 'Tron',
	xrp = 'XRP',
	bnb = 'Binance Coin',
	ltc = 'Litecoin',
	bch = 'Bitcoin Cash',
	ada = 'Cardano',
	etc = 'Ethereum Classic',
	uni = 'Unicoin',
	dot = 'Polkadot',
	xlm = 'Stellar',
	shib = 'Shiba Inu',
	sushi = 'SushiCoin',
	matic = 'Polygon',
	op = 'Optimism L2',
	pepe = 'Pepe Coin',
	bonk = 'Bonk',
	floki = 'FLOKI',
}
